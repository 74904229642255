import React, {useState, useEffect} from 'react'
import './Game.css'
import { useLoaderData } from "react-router-dom";
import Navbar from '../../Components/Navbar/Navbar';
import * as sheetApi from '../../requests/sheet'
import PlayerStatsTable from '../../Components/StatsTable/PlayerStatsTable'
import ResponsiveNavbar from '../../Components/ResponsiveNavbar/ResponsiveNavbar';

export async function loader({ params }) {
    //const contact = await getContact(params.date);
    let datestring = `${params['month']}-${params['day']}-${params['year']}`

    let res = await sheetApi.getGameInfo(datestring, params["home-team"], params["away-team"])

    if (Object.keys(res).length === 0) {
      throw new Response("Not Found", { status: 404 })
    }

    let home_team = params["home-team"]
    let away_team = params["away-team"]

    return {res, datestring, home_team, away_team};
  }

function Game() {
    const params = useLoaderData();

    const [homeStats, setHomeStats] = useState([])
    const [awayStats, setAwayStats] = useState([])
    const [selectedTeam, setSelectedTeam] = useState('')
    const [teams, setTeams] = useState()
    const [datestring, setDateString] = useState('')
    //let date = new Date(params['year'], params['month'], params['day'])
    useEffect(() => {
      
      setHomeStats(params.res["home"])
      setAwayStats(params.res["away"])
      setSelectedTeam(params["home_team"])
      setDateString(params["datestring"])
      setTeams([params["home_team"], params["away_team"]])
    }, [])

    const handleTeamClick = (team) => {
      setSelectedTeam(team);
    }
        const selectedTeamStats = selectedTeam == params["home_team"] ? homeStats : awayStats

  return (
    <div>
        <ResponsiveNavbar />
        <div className='teamsWrapper'>
          {/* Team Selector */}
          <ul className='gameTeamNavbar'>
            {teams?.map(team => (
              <li key={team} onClick={() =>handleTeamClick(team)} className={selectedTeam === team ? 'selected' : ''}>{team.toUpperCase()}</li>
            ))}
          </ul>
        </div>
        <PlayerStatsTable key={`stats-home`} teamPlayerStats={selectedTeamStats}/>
    </div>
  )
}

export default Game