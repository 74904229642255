import React from 'react'
import './Hero.css'
import hero_img from '../../assets/bball1-player.webp'
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

function Hero() {
  return (
    <div className='hero'>
        <div className='hero__image'>
            <div className='hero__tint'></div>
            <img src={hero_img}></img>
            <div className='hero__title'>
                <h1>WELCOME TO THE JUNGLE LEAGUE</h1>
            </div>

            <div className='hero__social__media'>
                    <span><FaInstagram/></span>
                    <span><FaYoutube/></span>
                    <span><FaTwitter/></span>
                    <span><FaFacebook/></span>
            </div>
        </div>
    </div>
  )
}

export default Hero