import React, {useState, useEffect} from 'react'
// import Navbar from '../../Components/Navbar/Navbar'
import ResponsiveNavbar from '../../Components/ResponsiveNavbar/ResponsiveNavbar'
import PlayerStatsTable from '../../Components/StatsTable/PlayerStatsTable'
import './Teams.css'
import * as sheetApi from '../../requests/sheet'


function Teams() {

  const [selectedTeam, setSelectedTeam] = useState('')

  const [teams, setTeams] = useState([])
  const [teamPlayerStats, setTeamPlayerStats] = useState({})

  useEffect(() => {
    
    sheetApi.getAllTeamStats().then(res => {
        console.log(res["AllTeamData"])
        let data = res["AllTeamData"]
        setTeams(Object.keys(data))
        setSelectedTeam(Object.keys(data)[1])
        setTeamPlayerStats(data)
    })
  }, [])


  const handleTeamClick = (team) => {
    setSelectedTeam(team);
  }

    // Find the playerStats for the selected team
    const selectedTeamStats = teamPlayerStats[selectedTeam] || [];

  return (
    <div className='teams'>
        <ResponsiveNavbar />
        <div className='teamsWrapper'>
          {/* Team Selector */}
          <ul className='teamNavbar'>
            {teams.map(team => (
              <li key={team} onClick={() =>handleTeamClick(team)} className={selectedTeam === team ? 'selected' : ''}>{team.toUpperCase()}</li>
            ))}
          </ul>
        </div>
        {/* Tables based off of selected item */}
        <PlayerStatsTable key={`stats-${selectedTeamStats}`}teamPlayerStats={selectedTeamStats} isTeam={true} />
    </div>
  )
}

export default Teams