import axios from 'axios'

//const base_url = 'https://backend-nmzk.onrender.com'
//const base_url = 'http://localhost:4000'

const base_url = process.env.REACT_APP_BACKEND_URL

const getTeam = async (teamName) => {
    const result = await axios.get(`${base_url}/api/schedule`)
    console.log(result)
    return result.data
}

const getSchedule = async () => {
    const result = await axios.get(`${base_url}/api/schedule`)
    console.log(result)
    console.log(typeof(result.data))
    return result.data
}

const getGameInfo = async (date, home_team, away_team) => {
    const result = await axios.get(`${base_url}/api/game/date/${date}/${home_team}/${away_team}`)
    console.log(result)
    console.log(typeof(result.data))
    return result.data
}

const getAllTeamStats = async () => {
    const result = await axios.get(`${base_url}/api/team/getallteams`)
    console.log(result)
    console.log(typeof(result.data))
    return result.data
}

const getFinishedGamesList = async () => {
    const result = await axios.get(`${base_url}/api/game/finished-game-list`)
    console.log(result)
    console.log(typeof(result.data))
    return result.data
}

const getStandings = async () => {
    const result = await axios.get(`${base_url}/api/standings`)
    console.log(result)
    console.log(typeof(result.data))
    return result.data
}


export {
    getTeam,
    getSchedule,
    getAllTeamStats,
    getFinishedGamesList,
    getStandings,
    getGameInfo
}