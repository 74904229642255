import React from 'react'
import jnglgueImg from '../../assets/jungleleague-pic1.png'
import './Navbar.css'


function Navbar() {

  return (
    <div>
          <div className='app__announcement'><p>JUNGLE LEAGUE STARTING MAY 19, 2024. REGISTRATIONS ARE NOW OPEN . SIGN UP</p></div>

          <div className='navbar'>
              <div className='navbar__left'>
                  <img className='navbar__logo' src={jnglgueImg} ></img>
              </div>

              <div className='navbar__middle'>
                      <a href="/"><p>Home</p></a>
                      <a href="/teams"><p>Teams</p></a>
                      {/* <a href="/schedule"><p>Schedule</p></a> */}
                      <a href="/standings"><p>Standings</p></a>
                      <a href="/scores"><p>Scores</p></a>
              </div>

              <div className='navbar__right'>
                      <button>REGISTER NOW</button>
              </div>

              
          </div>
    </div>
  )
}

export default Navbar