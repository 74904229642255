import React from "react";
import "./PlayerStatsTable.css";

const PlayerStatsTable = ({ teamPlayerStats, isTeam }) => {
  console.log("PLAYER STATS");
  console.log(teamPlayerStats);
  return (
    <div className="player-stats-container">
      <table className="stats-table">
        <thead>
          <tr>
            <th>#</th>
            <th className="player_column">Player</th>
            {
                isTeam ? <th>GP</th> : <></>
            }
            <th>PTS</th>
            <th>AST</th>
            <th>STL</th>
            <th>BLK</th>
            <th>REB</th>
            <th>FGM</th>
            <th>FGA</th>
            <th>FG%</th>
            <th>2PM</th>
            <th>2PA</th>
            <th>2P%</th>
            <th>3PM</th>
            <th>3PA</th>
            <th>3P%</th>
            <th>FTM</th>
            <th>FTA</th>
            <th>FT%</th>
            <th>OREB</th>
            <th>DREB</th>
            <th>PF</th>
            <th>TF</th>
            <th>TO</th>
            <th>EF</th>
          </tr>
        </thead>
        <tbody>
          {teamPlayerStats.map((stat) => (
            <tr key={stat["Name"]}>
              <td>{stat["#"]}</td>
              <td>{stat["Name"]}</td>
              {
                isTeam ? <td>{stat["GP"]}</td> : <></>
              }
              <td>{stat["PTS"]}</td>
              <td>{stat["AST"]}</td>
              <td>{stat["STL"]}</td>
              <td>{stat["BLK"]}</td>
              <td>{stat["REB"]}</td>
              <td>{stat["FGM"]}</td>
              <td>{stat["FGA"]}</td>
              <td>{stat["FG%"]}</td>
              <td>{stat["2PM"]}</td>
              <td>{stat["2PA"]}</td>
              <td>{stat["2P%"]}</td>
              <td>{stat["3PM"]}</td>
              <td>{stat["3PA"]}</td>
              <td>{stat["3P%"]}</td>
              <td>{stat["FTM"]}</td>
              <td>{stat["FTA"]}</td>
              <td>{stat["FT%"]}</td>
              <td>{stat["OREB"]}</td>
              <td>{stat["DREB"]}</td>
              <td>{stat["PF"]}</td>
              <td>{stat["TF"]}</td>
              <td>{stat["TO"]}</td>
              <td>{stat["EF"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlayerStatsTable;
