import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'

// import movieReducer from './reducers/movieReducer'

const store = configureStore({
  reducer: {
    // nominatedMovies: movieReducer
  }

})

export default store