import React from 'react'
import './ScoreRow.css'

const ScoreRow = ({score}) => {

  return (
    /* This is just placeholder code for the demo */
    <div className='scoreRow' >
      <a href={`/game/${score["Date"]}/${score["Home Team"]}/${score["Away Team"]}`}>
        <div className='scoreRow__score'>
        <div className='teamBox'>
            <div>{score["Home Team"]}</div>
        </div>
        <div className='score'>
            <div>
              {
                score["game_score"] == "Coming Soon" ? score["game_score"] : `${score["game_score"][0]["Score"]}-${score["game_score"][1]["Score"]}`
              }
            </div>
        </div>
        <div className='teamBox'>
            <div>{score["Away Team"]}</div>
        </div>
        </div>
      </a>
    </div>
  )
}

export default ScoreRow
