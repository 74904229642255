import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ResponsiveNavbar from '../../Components/ResponsiveNavbar/ResponsiveNavbar'

import Hero from '../../Components/Hero/Hero'

function Home() {
  return (
    <div>
       <ResponsiveNavbar />
       <Hero></Hero>        
    </div>
  )
}

export default Home