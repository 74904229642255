import React, { useState, useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ResponsiveNavbar from '../../Components/ResponsiveNavbar/ResponsiveNavbar'

import './Scores.css'
import ScoreRow from '../../Components/ScoreRow/ScoreRow'
import * as sheetApi from '../../requests/sheet'


function Scores() {

  const [scoresList, setScoresList] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  useEffect(() => {
    sheetApi.getFinishedGamesList().then(res => { 
      let data = res.finishedGames

      setScoresList(data)
      setDataLoaded(true) // do we need this?
    })

  }, [])
  
  return (
    <div className='scores'>
      <ResponsiveNavbar />
      <div className='scores-container'>
        {/* This is just placeholder code for the demo */}
        {/* <h1>Sunday May 19th</h1>
        <ScoreRow />
        <ScoreRow />
        <ScoreRow />
        <ScoreRow />
        <ScoreRow />
        <h1>Sunday May 26th</h1> */}

        {
        scoresList.map((score) => (
          <ScoreRow  score={score} key={`${score["Date"]}-${score["Home Team"]}-${score["Away Team"]}`}/>
        ))
        }
      </div>
    </div>
  )
}

export default Scores