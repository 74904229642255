import React, { useState } from 'react'
import jnglgueImg from '../../assets/jungleleague-pic2.png'
import { CiMenuBurger } from "react-icons/ci";
import './ResponsiveNavbar.css'

const ResponsiveNavbar = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <div>
          <div className='app__announcement'><p>JUNGLE LEAGUE STARTING MAY 19, 2024. REGISTRATIONS ARE NOW OPEN . SIGN UP</p></div>

          <div className={menuOpen ? 'navbar__open' : 'navbar'}>

              <div className={menuOpen ? 'navbar__left__open' : 'navbar__left'}>
                  <img className='navbar__logo' src={jnglgueImg} ></img>
              </div>

              <div className='navbar__toggle' onClick={() => toggleMenu()}>
                <CiMenuBurger className='navbar__menuburger' />
              </div>

                <div className={menuOpen ? 'navbar__middle__open' : 'navbar__middle'}>
                            <a href="/"><p>Home</p></a>
                            <a href="/teams"><p>Teams</p></a>
                            {/* <a href="/schedule"><p>Schedule</p></a> */}
                            <a href="/standings"><p>Standings</p></a>
                            <a href="/scores"><p>Scores</p></a>
                </div>
                
              <div className='navbar__right'>
                      <button>REGISTER NOW</button>
              </div>

          </div>
    </div>
  )
}

export default ResponsiveNavbar
