import './App.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  createStaticHandler,
  createStaticRouter,
  StaticRouterProvider,
} from "react-router-dom/server";
import { ChakraProvider } from '@chakra-ui/react'

import Home from './pages/Home/Home';
import Schedule from './pages/Schedule/Schedule';
import Teams from './pages/Teams/Teams';
import Stats from './pages/Stats/Stats';
import Scores from './pages/Scores/Scores';
import Error from './pages/Error/Error';
import Game, {loader as GameLoader} from './pages/Game/Game';

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "schedule/",
    element: <Schedule/>
  },
  {
    path: "teams/",
    element: <Teams/>
  },
  {
    path: "standings/",
    element: <Stats/>
  },
  {
    path: "scores/",
    element: <Scores/>
  },
  {
    path: "game/:month/:day/:year/:home-team/:away-team/",
    element: <Game />,
    loader: GameLoader,
    errorElement: <Error />
  }
]);

function App() {
  return (
    <div className="App">
      <ChakraProvider>
          {/* <Router>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/schedule" element={<Schedule />} />
                <Route path="/teams" element={<Teams />} />
                <Route path="/standings" element={<Stats />} />
                <Route path="/scores" element={<Scores />} />
                <Route path="game/:month/:day/:year/:home-team/:away-team/" element={<Game />} loader={GameLoader} errorElement={<Error />}/>
            </Routes>
          </Router> */}
          <RouterProvider router={routes} />
      </ChakraProvider>

    </div>
  );
}

export default App;
