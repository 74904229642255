import React, {useState, useEffect}  from 'react'
import './TeamStandings.css'
import * as sheetApi from '../../requests/sheet'


const TeamStandings = () => {

  const [standings,setStandings] = useState([]);

  useEffect(() => {
    
    sheetApi.getStandings().then(res => {
      console.log(res.Standings);
      const standings_data = res.Standings;
      let filteredStandings = standings_data.sort((a, b) => b.Wins - a.Wins);
      setStandings(filteredStandings);
    })
  }, [])
    
    // const teamStats = [
    //     { teamName: 'Average Joes', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'W5'},
    //     { teamName: 'Blaze Warriors', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'W2'},
    //     { teamName: 'Bluff Goons', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'L1'},
    //     { teamName: 'Chip Chasers', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'W3'},
    //     { teamName: 'Dream Team', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'W1'},
    //     { teamName: 'East End Eagles', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'L3'},
    //     { teamName: 'Jelly Fam', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'L2'},
    //     { teamName: 'Spidaaaa', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'W1'},
    //     { teamName: 'Swishaz', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'L2'},
    //     { teamName: 'Uncle Drews', gamesPlayed: 10, Wins: 0, Losses: 0, Streak: 'L5'},
    //   ];

  return (
    <div className="team-stats-container">
    <h1>Team Standings</h1>
    <table className="stats-table">
      <thead>

        <tr>
          <th>Team</th>
          <th>GP</th>
          <th>Wins</th>
          <th>Losses</th>
          <th>Streak</th>
        </tr>
      </thead>
      <tbody>
        {standings.map(team => (
          <tr key={team.Team}>
            <td>{team.Team}</td>
            <td>{team.GP}</td>
            <td>{team.Wins}</td>
            <td>{team.Losses}</td>
            <td>{team.Streak}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default TeamStandings
