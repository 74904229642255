import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ResponsiveNavbar from '../../Components/ResponsiveNavbar/ResponsiveNavbar'
import TeamStandings from '../../Components/StatsTable/TeamStanding'

function Stats() {
  return (
    <div className='stats'>
        {/* <Navbar /> */}
        <ResponsiveNavbar />
        <TeamStandings />
    </div>
  )
}

export default Stats