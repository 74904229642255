import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ResponsiveNavbar from '../../Components/ResponsiveNavbar/ResponsiveNavbar'

import {
  Text
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import * as sheetApi from '../../requests/sheet'
import './Schedule.css'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box
} from '@chakra-ui/react'
import './MatchRow.css'


const MatchRow = ({TeamA, TeamB, time}) => {
  return (
    <div className='matchRow'>
      <div className='teamBox'>
        <div>{TeamA}</div>
      </div>
      <div className='versus'>
        <div>{time}</div>
        <div>vs</div>
      </div>
      <div className='teamBox'>
        <div>{TeamB}</div>
      </div>
    </div>
  )
}


function MyTable(props) {
    
  return (
    <div className='matchContainer'>
    {props.tableData.map((row) => (
      <MatchRow TeamA={row["Home Team"]} TeamB={row["Away Team"]} time={row["Time"]}/>
    ))}
  </div>
  )
}

function Schedule() {

  const [schedule, setSchedule] = useState([])
  const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const month = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    sheetApi.getSchedule().then(res => {
          const schedule_data = res.Schedule
          let sortedSchedule = {}
          for ( let row of schedule_data) {
            let parse_date = row["Date"].split('/')
            let month = parse_date[0]
            let day = parse_date[1]
            let year = parse_date[2]
            let d = new Date(year, month-1, day)
            row['formattedDate'] = d
            //sortedSchedule[row["Date"]] = ["hi"]
            
            if (Object.keys(sortedSchedule).includes(row["Date"])) {
              sortedSchedule[row["Date"]].push(row)
            }
            else {
              sortedSchedule[row["Date"]] = [row]
            }
          }
    
          console.log(sortedSchedule)
          setSchedule(sortedSchedule)
      })
  }, [])

  return (
    <div className='schedule'>
        <ResponsiveNavbar />
        <div className='schedule__accordion'>
        {
          Object.keys(schedule).map(date => {
            const datesplit = date.split('/')
            let actualDate = new Date(datesplit[2], datesplit[0]-1, datesplit[1])
            return (
              <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      
                      <Text id="schedule__accordion__header" as='b' fontSize='xl'>{`${week[actualDate.getDay()]} ${month[actualDate.getMonth()]} ${actualDate.getDate()}, ${actualDate.getFullYear()}`}</Text>

                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} textAlign='center'>
                  <br/>
                  <br/>
                  <MyTable tableData={schedule[date]} />
                  <br/>
                  <br/>
                </AccordionPanel>
              </AccordionItem>
            
            </Accordion>
            )
          })
        }
        </div>

        <div >
</div>
      </div>
  )
}

export default Schedule